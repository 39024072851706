.email-success {
    /* padding:3rem 4rem; */
}

.email-icon {
    margin-left: -40px;
}

.link-button {
    border: none;
    background: none;
    color: #037CB7;
    margin: 0;
    padding: 0;
    cursor: pointer;
    text-decoration: underline;
}

@media screen and (max-width: 599px) {
    .resend-block {
        text-align: center
    }

    .email-icon {
        width: 35vw;
        margin-left: 0;
    }

    .email-iconimg {
        width: 35vw;
    }

    .validation-text {
        font-size: 30px !important;
        line-height: 48px !important;
    }
}