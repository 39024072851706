.MuiActive-chip {
    background: #F7F9E6 !important;
    border-color: #F7F9E6 !important;
    border-radius: 8px !important;
    color: #5E9E42 !important;
}

.doneChip-icon {
    color: #5E9E42 !important;
}

.MuiInactive-chip {
    background: #E6EEF4 !important;
    border-color: #E6EEF4 !important;
    border-radius: 8px !important;
}

.Mui-tabs {
    padding: 0 3px !important;
    color: #525252 !important;
}

.Mui-tabs.Mui-selected {
    font-weight: bold !important;
}

.boxi {
    display: flex;
}

.boxi-inside {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
    padding-bottom: 20px;
}

.boxi-inside-inside {
    align-self: flex-end;
}


.qr-code-company-url {
    background-color: #ECF9E6;
    height: 94px;
    width: 94px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    border: 1px solid #5E9E42;
    border-radius: 8px;
}




#simple-tabpanel-0 .MuiBox-root,
#simple-tabpanel-1 .MuiBox-root {
    padding: 0 !important;
}