@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

.App {
    background-color: #EEF2F5;
    width: auto;
    height: 100%;
    min-height: 100vh;
    margin: 0; 
}
.content{
    width: 85%;
    margin: auto;
    min-height: 80vh;
    /* background-color: white; */
    /* padding: 20px; */
    /* border-radius: 5px; */
    margin:20px auto 20px auto;
}

