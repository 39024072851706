#adminPortal {
    margin: 0;
    width: 100%;
    /* height: 100vh; */
}

body {
    margin: 0;
}

