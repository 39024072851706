.companylist-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

.company-name {
    color: #037CB7;
    cursor: pointer;
}

.MuiTableCell-root.table-pagination {
    border-bottom: none;
    float: right;
    display: contents
}

.edit-company {
    width: 100%
}

.MuiAddMore-button {
    padding: 8px 16px !important;
}

.MuiDivider-webform {
    border-color: #B9D4E9 !important;
    margin: 10px 0 !important;
}