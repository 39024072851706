.MuiInputLabel-asterisk {
    color: #B30303;
}

.MuiFormLabel-asterisk {
    color: #B30303;
}

.partner-switch {
    width: inherit;
    background-color: #EEF2F5;
    margin: 2px 5px 2px 0px;
    padding: 10px 10px 10px 20px;
}

.partner-switch .MuiSelect-select {
    padding: 6px 18px !important;
}

.MuiButton-startIcon {
    margin-right: 0 !important;
}

.MuiPaginationIcon-color {
    color: #037cb7 !important;
}

.MuiButton-root,
.MuiLoadingButton-root {
    padding: 13px 16px !important;
    border-radius: 8px !important;
}

.MuiButton-text {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

/* Alert */
.custom-alert.MuiSnackbar-root {
    width: 100%;
    top: 5rem;
}

.custom-alert.MuiSnackbar-root.stickyalert {
    position: fixed;
    transform: none;
    top: 0;
    left: 0;
}

.custom-alert .MuiPaper-root {
    padding: 0px 100px;

}

.MuiSvgIcon-root {
    font-size: 1rem !important;
}

.MuiTypography-root {
    overflow-wrap: break-word;
    color: #525252 !important;
}

.MuiDivider-dialog {
    border: 2px solid #99D6DB !important;
    background-color: #99D6DB !important;
    border-radius: 2px !important;
    width: 10%;
    margin-left: 30px !important;
}

.MuiDialog-circularButton {
    padding: 0 !important;
}

.MuiDialog-circularButton .MuiSvgIcon-root {
    font-size: 30px !important;
    color: #037CB7 !important;

}

.MuiDialog-Buttons {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
}

.Mui-customDialog .MuiPaper-root {
    border-radius: 12px !important;
    max-width: 500px !important;
}

.MuiAlert-standardSuccess {
    background-color: #F7F9E6 !important;
    border-top: 3px solid #5E9E42 !important;
    color: #525252 !important;

}

.MuiAlert-standardError {
    background-color: #FAEAEA !important;
    border-top: 3px solid #B30303 !important;
    color: #525252 !important;

}

.MuiAlert-standardSuccess .MuiSvgIcon-root {
    color: #5E9E42 !important;
    padding: 0px 0
}

.MuiAlert-standardError .MuiSvgIcon-root {
    color: #B30303 !important;
    padding: 0px 0
}

.MuiAlert-action .MuiSvgIcon-root {
    color: #525252 !important;
    padding: 0px 0;
}

.MuiInputBase-root {
    background: white;
}

@media screen and (max-width: 599px) {
    .custom-alert.MuiSnackbar-root {
        width: 100%;
        top: 5rem;
        left: 0 !important;
        right: 0 !important;
    }

    .MuiAlert-standardSuccess .MuiSvgIcon-root {
        color: #5E9E42 !important;
        padding: 0px 0
    }

    .MuiAlert-standardError .MuiSvgIcon-root {
        color: #B30303 !important;
        padding: 0px 0
    }

    .MuiAlert-action .MuiSvgIcon-root {
        color: #525252 !important;
        padding: 0px 0;
    }
}