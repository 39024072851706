.ql-picker.ql-font .ql-picker-label[data-value="open"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="open"]::before
{
    content: 'Open Sans';  font-family: 'Open Sans', sans-serif;
}

.ql-picker.ql-font .ql-picker-label[data-value="roboto"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="roboto"]::before
{
    font-family: 'roboto';
    content: "Roboto";
}
.ql-picker.ql-font .ql-picker-label[data-value="lateef"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="lateef"]::before
{
    font-family: "Lateef", cursive;
    content: "Lateef";
}

.ql-font-lateef {
    font-family: "lateef", cursive;
  }
  .ql-font-roboto {
    font-family: "roboto"
  }
  /* Set content font-families */
  .ql-font-open {
    font-family: "Open Sans";
  }
  .ql-container{
      font-family: "roboto"
  }