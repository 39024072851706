.login{
display: 'flex'; 
flex-flow: 'row';
height: '100%';
min-height: '100vh'
}
.login-line{
   width: 44px;
   height: 0px;
   border: 2px solid #FAC832;
   border-radius: 5px;
   background-color: #FAC832;
   margin-bottom: 10px;
}

.login-background{
    width: 100%;
    background-color: #025780;
    position: relative;
}
.login-content{
   margin: 12vh 8vw;
}
.login-logo{
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.login-logo img{
    width: 15rem
}
.login-text .MuiTypography-root{
    color: white !important;
}
.login-text{
    position: absolute;
    left: 15.04%;
    right: 12.29%;
    top: 65.53%;
    bottom: 23.34%;
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 43px;
}
.login-image{
    height: 50vh;
    width: 40vw;
    position: absolute;
    left: 2.4%;
    right: 9.36%;
    top: 12.63%;
    bottom: 39.26%;
}

.login-image img{
    height: 100%;
    width: 100%;
}

.email-label{
    display:flex;
    justify-content:space-between;
    margin-bottom:10px;
}

.login-error{
    background-color: #FDF0E6 !important;
}
.login-error .MuiAlert-message{
padding: 0
}

@media screen and (max-width: 599px) {
    .login { display: flex; flex-flow: column; }
    .login-background{
        width: 100%;
        height: 30vh;
    }
    .login-text{
        display: none
    }
    .login-image{
        position: unset !important;
        display: inline !important;
    }
    .login-btn{
      width:100%
    }
    .login-content{
        margin: 5vh 8vw;
     }
    
}