.header{
display: flex;
position: relative;
flex-direction: row;
align-items: center;
width: 100%;
background: #FFFFFF;
}

.header img{
    padding: 10px;
    margin: 18px 0;
    margin-left: 6.8%;
    width: 13rem;
}